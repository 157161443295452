<template>
  <div class="lock-subscribe-table relative">
    <v-data-table
      :headers="headers"
      :items="data"
      :mobile-breakpoint="0"
      class="bg-cardbg radius-10 mt-6 blur-bg"
      hide-default-footer
    >
      <template
        v-slot:[`item.${header.key}`]
        v-for="(header, i) in headers"
        :key="i"
      >
        <div class="d-flex align-center">
          <span>Dummy Data</span>
        </div>
      </template>
    </v-data-table>
    <div class="lock-subscribe-table__btn z-9">
      <v-btn
        color="primary"
        elevation="0"
        class="radius-10"
        height="42"
        @click="$router.push('/subscribe')"
      >
        <span class="font-16 d-inline-block mx-5 text-white">Subscribe</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => ([
        { title: "Name", key: "Name" },
        { title: "Price", key: "Price" },
        { title: "Date", key: "Date" },
        { title: "Column 1", key: "Column 1" },
        { title: "Column 2", key: "Column 2" },
      ]),
    },
  },
  data: () => ({
    data: [{}, {}, {}, {}, {}],
  }),
};
</script>

<style lang="scss">
.img-loader {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.text-loader {
  width: 80px;
  height: 18px;
  border-radius: 3px;
}
.lock-subscribe-table {
  &__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
