<template>
  <v-app class="app">
    <transition name="fade" mode="out-in">
      <div class="loader" v-if="!appLoaded">
        <img
          width="35%"
          :src="require('@/assets/images/global/mrb_loading.gif')"
        />
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div v-if="appLoaded">
        <navbar-app v-if="navbarVisible" :key="loginChanged"></navbar-app>
        <router-view class="pages"></router-view>
        <snackbar></snackbar>
        <chat-modal
          v-if="
            $route.name != 'Login' &&
            $route.name != 'Signup' &&
            $route.name != 'Forget-Password'
          "
        ></chat-modal>
        <footer-app></footer-app>
      </div>
    </transition>
  </v-app>
</template>

<script>
import Snackbar from "@/components/global/SnackbarComponent";
import NavbarApp from "./layout/NavbarApp.vue";
import { mapGetters, useStore } from "vuex";
import ChatModal from "./components/core/ChatModal.vue";
import FooterApp from "./layout/FooterApp.vue";

export default {
  name: "App",
  components: {
    Snackbar,
    NavbarApp,
    ChatModal,
    FooterApp,
  },
  setup() {
    const store = useStore();

    return { store };
  },
  data: () => ({
    appLoaded: false,
  }),
  computed: {
    ...mapGetters(["loginChanged", "noUserRoutes"]),
    navbarVisible() {
      if (this.noUserRoutes.includes(this.$route.name)) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {},
  mounted() {
    this.store.dispatch("setToken", {
      token: localStorage.getItem("token") || "",
    });
    setTimeout(() => {
      this.appLoaded = true;
    }, 2500);
  },
};
</script>

<style lang="scss">
.app {
}
.loader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fcfcff;
}
.pages{
  min-height: 800px
}
</style>
