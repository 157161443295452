// import { GetPorfileDetails } from "@/services/Auth";
// import store from "@/store";
import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: '/',
    redirect: '/auth/login',  // Redirect from `/` to `/auth/login`
  },
  {
    path: "/auth/",
    name: "Auth",
    meta: { requiresNoAuth: true, title: "Auth" },
    component: () => import("../views/auth/IndexView.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("../views/auth/login/IndexView.vue"),
        meta: { requiresNoAuth: true, title: "Login" },
      },
      {
        path: "signup",
        name: "Signup",
        component: () => import("../views/auth/register/IndexView.vue"),
        meta: { requiresNoAuth: true, title: "Signup" },
      },
      {
        path: "forget-password",
        name: "Forget-Password",
        component: () => import("../views/auth/forget-password/IndexView.vue"),
        meta: { requiresNoAuth: true, title: "Forget-Password" },
      },
    ],
  },
  {
    path: "/homepage",
    // component: () => import("../layout/NavbarApp.vue"),
    children: [
      {
        path: "/homepage",
        name: "Homepage",
        component: () => import("../views/dashboard/homepage/IndexView.vue"),
        meta: { requiresAuth: false, title: "Homepage" },
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: () => import("../views/dashboard/notifications/IndexView.vue"),
        meta: { requiresAuth: false, title: "Notifications" },
      },
      {
        path: "/top-traders",
        name: "Top-Traders",
        component: () => import("../views/dashboard/top-traders/IndexView.vue"),
        meta: { requiresAuth: true, title: "Top-Traders" },
      },
      {
        path: "/top-traders/:id",
        name: "Top-Traders-ID",
        component: () => import("../views/dashboard/top-traders/id/IndexView.vue"),
        meta: { requiresAuth: true, title: "Top-Traders-ID" },
      },
      {
        path: "/data-page",
        name: "Data-Page",
        component: () => import("../views/dashboard/data-page/IndexView.vue"),
        meta: { requiresAuth: true, title: "Data-Page" },
      },
      {
        path: "/become-trader",
        name: "Become-Trader",
        component: () => import("../views/dashboard/become-trader/IndexView.vue"),
        meta: { requiresAuth: true, title: "Become-Trader" },
      },
      {
        path: "/corner",
        name: "Corner",
        component: () => import("../views/dashboard/corner/IndexView.vue"),
        meta: { requiresAuth: true, title: "Corner" },
      },
      {
        path: "/portfolio",
        name: "Portfolio",
        component: () => import("../views/dashboard/portfolio/IndexView.vue"),
        meta: { requiresAuth: true, title: "Portfolio" },
      },
      {
        path: "/subscribe",
        name: "Subscribe",
        component: () => import("../views/dashboard/subscribe/IndexView.vue"),
        meta: { requiresAuth: true, title: "Subscribe" },
      },
      {
        path: "/my-profile",
        name: "My-Profile",
        component: () => import("../views/dashboard/profile/IndexView.vue"),
        meta: { requiresAuth: true, title: "My-Profile" },
      },
      {
        path: "/news",
        name: "News",
        component: () => import("../views/dashboard/news/IndexView.vue"),
        meta: { requiresAuth: false, title: "News" },
      },
      {
        path: "/news/:id",
        name: "News-ID",
        component: () => import("../views/dashboard/news/id/IndexView.vue"),
        meta: { requiresAuth: false, title: "News-ID" },
      },
      {
        path: "/news/section/:newsTab",
        name: "News-Section",
        component: () => import("../views/dashboard/news/sub-page/IndexView.vue"),
        meta: { requiresAuth: false, title: "News-Section" },
      },
    ]
  },
];


const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let token = localStorage.getItem("token");
  // if (token) {
  //   if (!store.getters.userDetails.fullName) {
  //     let res = await GetPorfileDetails();
  //     if (localStorage.getItem("token") && res.message == "Invalid token") {
  //       localStorage.removeItem("token");
  //       next({
  //         path: "/auth/login",
  //       });
  //     }
  //     store.dispatch("setUserDetails", res.data.data);
  //     store.commit("SET_USER", { token: token });
  //   }
  // }
  // check if logged
  let isLogin = token;

  if (to.matched.some((record) => record.meta.requiresNoAuth)) {
    if (isLogin) {
      next({
        path: "/homepage",
      });
    } else {
      next();
    }
    return;
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    // if not, redirect to login page.
    if (isLogin) {
      next();
    } else {
      next({
        path: "/auth/login",
      });
    }
    return;
  } else {
    next();
  }

});

export default router;
