<template>
  <div>
    <!-- Button to open the chat popup -->
    <v-btn class="chat-button" color="primary" fab @click="isChatOpen = true">
      <img
        class="chat-icon"
        :src="require('@/assets/images/icons/ai-icon.png')"
        alt=""
      />
    </v-btn>

    <!-- Chat popup -->
    <transition name="fade" mode="out-in">
      <div
        class="popup-chat radius-10"
        v-if="isChatOpen"
        persistent
        max-width="400px"
      >
        <div class="d-flex align-center justify-space-between px-4 py-4">
          <span class="text-grey3"> Chat with GPT </span>
          <v-icon color="text-grey3" @click="closeChatHandler"
            >mdi-close</v-icon
          >
        </div>
        <v-divider color="#8585a3"></v-divider>

        <div
          class="chat-list over-scroll px-4"
          ref="chatMessages"
          :key="isChatOpen"
        >
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="message.isSender ? ' justify-end' : ''"
            class="px-0 d-flex align-start mb-3 pt-2"
            style="flex-wrap: wrap-reserve"
          >
            <v-avatar class="chat-avatar" v-if="!message.isSender">
              <jazz-icon v-if="message.isSender" :diameter="40"></jazz-icon>
              <img
                v-else
                class="object-contain"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6CqmIbWlf0UxGroEMna38ZnqYv5K7GlqmGQ&s"
              />
            </v-avatar>
            <div class="mx-1 pt-1 message-width" v-html="message.text"></div>
            <v-avatar class="chat-avatar" v-if="message.isSender">
              <jazz-icon v-if="message.isSender" :diameter="40"></jazz-icon>
              <img
                v-else
                class="object-contain"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6CqmIbWlf0UxGroEMna38ZnqYv5K7GlqmGQ&s"
              />
            </v-avatar>
          </div>
        </div>

        <div class="px-2 py-2 d-flex align-center">
          <base-input
            :defaultSelected="newMessage"
            @changeSelected="newMessage = $event"
            placeholder="Type a message"
            @keyup.enter="sendMessageToChatHandler"
            class="w-100"
            :rules="[]"
          ></base-input>
          <div
            class="pa-2 radius-5 cursor-pointer"
            @click="sendMessageToChatHandler"
          >
            <v-icon color="white">mdi-send</v-icon>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { SendMessageToChat } from "@/services/General";
import { ref } from "vue";

export default {
  setup() {
    let loading = ref(false);
    let isChatOpen = ref(false);
    let newMessage = ref("");
    let chatMessages = ref("");
    let messages = ref([]);

    const sendMessageToChatHandler = async () => {
      if (newMessage.value.trim() !== "" && !loading.value) {
        // Add the sender's message
        messages.value.push({ text: newMessage.value, isSender: true });
        newMessage.value = "";
        loading.value = true;

        setTimeout(async () => {
          let res = await SendMessageToChat(newMessage.value);
          if (res?.askChatGpt) {
            loading.value = false;
            messages.value.push({
              text: res.askChatGpt?.data,
              isSender: false,
            });
            setTimeout(() => {
              if (chatMessages.value) {
                chatMessages.value.scrollTop =
                  chatMessages?.value?.scrollHeight;
              }
            }, 200);
          }
        }, 50);
      }
    };

    const closeChatHandler = () => {
      messages.value.splice(0, messages.value.length);
      newMessage.value = "";
      isChatOpen.value = false;
    };

    return {
      chatMessages,
      isChatOpen,
      newMessage,
      messages,
      sendMessageToChatHandler,
      closeChatHandler,
    };
  },
  methods: {},
};
</script>

<style scoped>
.chat-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.popup-chat {
  position: fixed;
  bottom: 100px;
  right: 120px;
  height: 425px;
  width: 400px;
  background: #191820;
}
.chat-list {
  height: 300px;
}
.message-width {
  max-width: 340px;
}

.sender {
  text-align: right;
  color: #007bff;
}

.receiver {
  text-align: left;
  color: #555;
}

v-card-text {
  max-height: 300px;
  overflow-y: auto;
}

v-card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat-icon {
  filter: invert(100%) brightness(100%) contrast(100%);
  width: 40px;
  height: 40px;
}
</style>
