<template>
  <div class="relative pt-3">
    <div class="text-right close-icon">
      <v-icon @click="$emit('close')">mdi-close</v-icon>
    </div>
    <div>
      <span class="font-700 text-white font-18 d-block mb-10"
        >Are you sure you want to singout?</span
      >
    </div>

    <v-row>
      <v-col cols="12" md="6">
        <v-btn
          width="100%"
          height="45px"
          color="#EEF1FF"
          type="submit"
          elevation="0"
          @click="$emit('close')"
        >
          <span class="text-primaryOpacity font-14 font-600">Cancel</span>
        </v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <v-btn
          width="100%"
          height="45px"
          type="submit"
          class="bg-primary-linear"
          elevation="0"
          @click="logoutHandler"
        >
          <span class="text-white font-14 font-600">Sign Out</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    return { store };
  },
  data: () => ({}),
  methods: {
    async logoutHandler() {
      await this.store.dispatch("setUserDetails", {});
      await this.store.dispatch("logout");
      await this.store.dispatch("showSnack", {
        text: "Logout Successfully",
        color: "primary",
      });
      this.$emit("close");
    },
  },
};
</script>

<style></style>
