<template>
  <div>
    <div class="bg-darkbg px-5 py-4">
      <div>
        <span class="font-16 font-500 d-block text-greyBtnText mb-2"
          >Trade type</span
        >
        <v-radio-group
          class="radio-line"
          v-model="obj.tradeType"
          inline
          :hideDetails="true"
        >
          <v-radio label="Futures" value="futures"></v-radio>
          <v-radio label="Spot" value="spot"></v-radio>
        </v-radio-group>
      </div>
      <div v-if="obj.tradeType == 'futures'">
        <span class="font-16 font-500 d-block text-greyBtnText mb-1 mt-4"
          >Direction</span
        >
        <v-radio-group
          class="radio-line"
          v-model="obj.timeTag"
          inline
          :hideDetails="true"
        >
          <v-radio label="Long" value="long"></v-radio>
          <v-radio label="Short" value="short"></v-radio>
        </v-radio-group>
      </div>
      <div>
        <span class="font-16 font-500 d-block text-greyBtnText mb-2 mt-3"
          >Choose Order</span
        >
        <v-radio-group
          class="radio-card border-dark radius-10"
          v-model="obj.orderType"
          inline
          :hideDetails="true"
        >
          <v-radio label="Market Order" value="market"></v-radio>
          <v-radio label="Limit Order" value="limit"></v-radio>
        </v-radio-group>
      </div>

      <div class="mt-4">
        <span class="font-16 font-500 d-block text-greyBtnText d-block mb-2"
          >Entry price</span
        >
        <div class="d-flex align-center">
          <base-input
            class="border-dark bg-cardbg radius-10 d-inline-block"
            placeholder="00.00"
            type="number"
            :disabled="obj.orderType == 'market'"
            :noBorder="true"
            :defaultSelected="entries[0].value"
            @changeSelected="entries[0].value = $event"
          ></base-input>

          <v-icon @click="toggleEntryPriceHandler"
            >mdi-{{ secondaryPriceAvailable ? "minus" : "plus" }}</v-icon
          >
        </div>
      </div>
      <div class="mt-4" v-if="secondaryPriceAvailable">
        <span class="font-16 font-500 d-block text-greyBtnText d-block mb-2"
          >Secondary price</span
        >
        <div class="d-flex align-center">
          <base-input
            class="border-dark bg-cardbg radius-10 d-inline-block"
            placeholder="00.00"
            type="number"
            :noBorder="true"
            :defaultSelected="entries[1].value"
            @changeSelected="entries[1].value = $event"
          ></base-input>

          <v-icon @click="toggleSecondaryHandler"
            >mdi-{{ thirdPriceAvailable ? "minus" : "plus" }}</v-icon
          >
        </div>
      </div>
      <div class="mt-4" v-if="thirdPriceAvailable">
        <span class="font-16 font-500 d-block text-greyBtnText d-block mb-2"
          >Third price</span
        >
        <div class="d-flex align-center">
          <base-input
            class="border-dark bg-cardbg radius-10 d-inline-block"
            placeholder="00.00"
            type="number"
            :noBorder="true"
            :defaultSelected="entries[2].value"
            @changeSelected="entries[2].value = $event"
          ></base-input>
        </div>
      </div>
    </div>
    <step-buttons
      btnTitle="Next : Set Goals"
      cancelTitle="Back"
      @cancel="$emit('back')"
      @clickHandler="nextHandler"
    ></step-buttons>
  </div>
</template>

<script>
import { useStore } from "vuex";
import StepButtons from "./ButtonsSection.vue";
export default {
  props: {
    selectedCoin: {
      type: Object,
      default: () => {},
    },
    objStep: {
      type: Object,
      default: () => {},
    },
  },
  components: { StepButtons },
  setup() {
    const store = useStore();

    return { store };
  },
  data: () => ({
    obj: {},
    entries: [{}],
    secondaryPriceAvailable: false,
    thirdPriceAvailable: false,
  }),
  computed: {
    isThirdValid() {
      return (
        +this.entries[2].value < +this.entries[1].value &&
        +this.entries[1].value < +this.entries[0].value
      );
    },
    isSecondValid() {
      return +this.entries[1].value < +this.entries[0].value;
    },
    isValid() {
      if (this.entries.length === 3) {
        return this.isThirdValid;
      }
      if (this.entries.length === 2) {
        return this.isSecondValid;
      }
      return true;
    },
  },
  methods: {
    toggleEntryPriceHandler() {
      if (this.secondaryPriceAvailable) {
        this.secondaryPriceAvailable = false;
        this.thirdPriceAvailable = false;
        this.entries.splice(1);
      } else {
        this.secondaryPriceAvailable = true;
        this.entries.push({});
      }
    },
    toggleSecondaryHandler() {
      if (this.thirdPriceAvailable) {
        this.thirdPriceAvailable = false;
        this.entries.splice(2, 1);
      } else {
        this.thirdPriceAvailable = true;
        this.entries.push({});
      }
    },
    nextHandler() {
      let errMessage = "";
      if (!this.entries[0].value) {
        errMessage = "You need to set entry price or change the Order Type";
      }
      if (this.obj.orderType == "limit") {
        if (this.obj.timeTag == "long" || this.objStep.tradeType == "spot") {
          if (this.entries[0].value > +this.selectedCoin?.price) {
            errMessage = "Entry price should be less than the market price";
          }
        } else {
          if (this.entries[0].value < +this.selectedCoin?.price) {
            errMessage = "Entry price should be higher than the market price";
          }
        }
      }

      if (this.entries.length >= 3 && !this.isThirdValid) {
        errMessage =
          "The third value must be less than the second, and the second must be less than the first.";
      }
      if (this.entries.length == 2 && !this.isSecondValid) {
        errMessage = "The second value must be less than the first.";
      }

      if (errMessage) {
        this.store.dispatch("showSnack", {
          text: errMessage,
          color: "error",
        });
        return;
      }

      this.obj.entries = this.entries;
      this.$emit("nextHandler", this.obj);
    },
  },
  created() {
    this.obj = this.objStep;
    this.obj.tradeType = "futures";
    this.obj.orderType = "market";
    this.entries[0].value = +this.selectedCoin?.price;
    console.log("this.obj", this.obj);
  },
};
</script>

<style></style>
