import { gql } from "@apollo/client/core";
import { useQuery, useMutation } from "@vue/apollo-composable";
import client from "@/plugins/apolloClient";

export const FetchUserProfile = async () => {
  const query = gql`
    query getProfile {
      getProfile {
        user {
          _id
          username
          role
          email
          fullName
              socialLinks {
                linkedIn
                twitter
              }
              createdAt

        }
        success
        message
      }
    }
  `;

  try {
    let { result, loading } = await useQuery(query);
    if (loading) {
      return result;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const FetchUserByID = async (id) => {
  let variables = {
    input: {
      id,
    },
  };

  try {
    const response = await client.query({
      query: gql`
        query getUserByIdr($input: getByIdInput!) {
          getUserById(input: $input) {
            user {
              _id
              username
              firstName
              lastName
              bio
              fullName
              socialLinks {
                linkedIn
                twitter
              }
              role
              experience {
                email
                name
              }
              followers
              following
              trades
              isFollowed
              followType
              createdAt
            }
            success
            message
          }
        }
      `,
      variables: variables,
      fetchPolicy: "no-cache",
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const ToggleFollowUser = async (id) => {
  let variables = {
    input: { id },
  };

  const { mutate: TOGGLE_FOLLOW } = await useMutation(
    gql`
      mutation toggleFollow($input: getByIdInput!) {
        toggleFollow(input: $input) {
          user {
            _id
            username
            firstName
            lastName
            bio
            socialLinks {
              linkedIn
              twitter
            }
            role
            experience {
              email
              name
            }
            followers
            following
            trades
            isFollowed
            followType
            createdAt
          }
          success
          message
        }
      }
    `,
    () => ({
      variables,
    })
  );

  try {
    let res = await TOGGLE_FOLLOW();
    if (res.data?.toggleFollow) {
      return res.data.toggleFollow;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const SubscribeToUser = async (id) => {
  let variables = {
    input: { id },
  };

  const { mutate: SUBSCRIBE_USER } = await useMutation(
    gql`
      mutation subscribeToUser($input: getByIdInput!) {
        subscribeToUser(input: $input) {
          user {
            _id
            username
            firstName
            lastName
            fullName
            bio
            socialLinks {
              linkedIn
              twitter
            }
            role
            experience {
              email
              name
            }
            followers
            following
            trades
            isFollowed
            followType
            createdAt
          }
          success
          message
        }
      }
    `,
    () => ({
      variables,
    })
  );

  try {
    let res = await SUBSCRIBE_USER();
    if (res.data?.subscribeToUser) {
      return res.data.subscribeToUser;
    }
  } catch (err) {
    console.log("err", err);
  }
};
