import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/axios";
import "./assets/style/_variables.scss";
import './assets/style/main.scss'
import clickOutside from './directives/click-outside';

// components
import LockSubscribe from './components/core/LockSubscribe'
import FieldColor from './components/core/FieldColor'
import BaseSelect from './components/base/SelectField'
import BaseInput from './components/base/InputField'
import BasePassword from './components/base/PasswordField'
import BaseTextarea from './components/base/TextareaField'
import BaseButton from './components/base/ButtonField'
import QRCodeVue3 from "qrcode-vue3";
import { VDateInput } from 'vuetify/labs/VDateInput'

// plugins
import moment from "moment";
import VueApexCharts from "vue3-apexcharts";
// import Purchases from "@revenuecat/purchases-js";


import '@splidejs/vue-splide/css';
import '@splidejs/vue-splide/css/core';

import { ApolloProvider } from '@vue/apollo-composable';
import { provideApolloClient, DefaultApolloClient } from '@vue/apollo-composable';
import client from './plugins/apolloClient';
import JazzIcon from "@/components/core/JazzIcon.vue";
import LoaderTable from "./components/core/LoaderTable.vue";
import LockSubscribeTable from "./components/core/LockSubscribeTable.vue";



const app = createApp(App)

app.config.productionTip = false

app.component("field-color", FieldColor);
app.component("base-select", BaseSelect);
app.component("base-button", BaseButton);
app.component("base-input", BaseInput);
app.component("base-date", VDateInput);
app.component("base-password", BasePassword);
app.component("base-textarea", BaseTextarea);
app.component("qr-code", QRCodeVue3);
app.component("lock-subscribe", LockSubscribe);
app.component("lock-subscribe-table", LockSubscribeTable);
app.component('jazz-icon', JazzIcon);
app.component('loader-table', LoaderTable);

app.provide("$axios", axios);


// Purchases.configure({ apiKey: 'sk_BfXraBefEzpSDCCJGKtFPFijnlglL' });

app.use(moment);
app.use(VueApexCharts);
app.use(ApolloProvider, { defaultClient: client });

provideApolloClient(client);
app.provide(DefaultApolloClient, client)

app.config.globalProperties.$stripeKey = `pk_test_51PVXTx046YPxyKP9wtM8weOrWTxh0cvTLWqDm1i5MHHPJx9NBv9d8HVlBsl4tW9laAgFPHIrBynFxzQFEbWN4URV00nmZEQQyr`
app.config.globalProperties.$revenuecatKey = `rcb_sb_HutJXNHJhPIBozHHWgjSRFxbr`
store.$revenuecatKey = app.config.globalProperties.$revenuecatKey

app.config.globalProperties.$symbolUrl = (item) => {
    return `https://cdn.coinglasscdn.com/static/img/coins/${item['slug']}-${item['symbol']}.png`
};

app.config.globalProperties.$shortenAddress = (address) => {
    const start = address.slice(0, 6);
    const end = address.slice(-3);
    return `${start}......${end}`;
}

app.provide('checkFieldFixedNumber', (val) => {
    val = val.toString()
    return val.includes('-') ? (+val.substring(1)).toFixed(2) : (+val).toFixed(2)
})
app.provide('checkNumberStatusByValue', (val) => {
    val = val.toString()
    if (val) {

        let value = val.includes('-') ? (+val.substring(1)).toFixed(2) : (+val).toFixed(2)
        if (value > 0.01) {
            return 'text-green1'
        } else if (value == 0.01) {
            return 'text-red1'
        } else {
            return ''
        }
    } else return ''
})
app.provide('checkFieldIcon', (val) => {
    val = val.toString()
    return val.includes('-') ? 'mdi-minus' : 'mdi-plus'
})
app.provide('checkFieldIconColor', (val) => {
    val = val.toString()
    return val.includes('-') ? 'red1' : 'green1'
})
app.provide('checkFieldTextColor', (val) => {
    val = val.toString()
    return val?.includes('-') ? 'text-red1' : 'text-green1'
})

app.provide('formatNumber', (num) => {
    if (num >= 1_000_000_000_000) return (num / 1_000_000_000_000).toFixed(2) + 'T';
    else if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(2) + 'B';
    else if (num >= 1_000_000) return (num / 1_000_000).toFixed(2) + 'M';
    else if (num >= 1_000) return (num / 1_000).toFixed(2) + 'K';
    else return num.toString();
})


app.provide('formatDemicalNumber', (num) => {
    let decimalFormatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 4
    })
    let formattedDecimal = decimalFormatter.format(+num);
    return formattedDecimal
})
// directives
app.directive('click-outside', clickOutside);

app.use(router).use(store).use(axios).use(vuetify).mount("#app");
