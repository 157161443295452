<template>
  <div class="z-9" :class="$vuetify.display.smAndDown ? 'text-center' : ''">
    <div class="footer-app  text-center">
      <div class="d-flex align-center justify-center">
        <span
          class="text-white font-16 d-inline-block mx-3 cursor-pointer"
          @click="goTo('https://innovate-x.co/terms')"
          >Terms</span
        >
        <span
          class="text-white font-16 d-inline-block mx-3 cursor-pointer"
          @click="goTo('https://innovate-x.co/privacy')"
          >Privacy</span
        >
      </div>
      <span class="font-16 text-grey5 d-block mt-3"
        >Copyright © 2024 MR.B. All rights reserved.</span
      >
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    footerLinks: [
      {
        title: "My account",
        links: [{ title: "Profile" }, { title: "My wallet" }],
      },
    ],
    message: null,
  }),
  methods: {
    goTo(link) {
      window.open(link, '_blank');
    },
  },
};
</script>

<style lang="scss">
.footer-app {
  margin-top: 4%;
  padding: 20px 0px;
  z-index: 8;
  background: rgba(35, 37, 48, 0.396)
}
</style>
