<template>
  <div class="pa-5 radius-8 bg-cardbg cursor-pointer relative lock-subscribe">
    <div class="lock-subscribe__btn z-9">
      <v-btn
        color="primary"
        elevation="0"
        class="radius-10"
        height="42"
        @click="clickButtonHandler"
      >
        <span class="font-16 d-inline-block mx-5">Subscribe</span>
      </v-btn>
    </div>
    <div class="blur-bg">
      <div class="d-flex align-center justify-space-between">
        <div class="d-inline-flex align-center bg-darkbg py-1 px-3 radius-30">
          <span class="font-14">Symbol </span>
        </div>

        <div class="d-flex align-center">
          <span class="pa-2 text-grey3 font-14 font-700 mr-3 uppercase">
            tradeType
          </span>
          <span
            class="pa-2 radius-4 font-14 font-700 uppercase primary-transparent text-primary"
            >time</span
          >
        </div>
      </div>

      <div class="d-flex align-center justify-space-between mb-2">
        <span class="d-block font-18 font-600 text-green1">Leverage x</span>
      </div>

      <div class="d-flex align-center justify-space-between">
        <div>
          <span class="d-block font-16 font-600 text-white ls-02">Symbol-</span>
          <span class="d-block font-12 font-600 text-grey3 ls-02"
            >Open Price</span
          >
        </div>

        <div>
          <span class="d-block font-16 font-600 text-white ls-02">Symbol </span>
          <span class="d-block font-12 font-600 text-grey3 ls-02"
            >Stop Loss</span
          >
        </div>
      </div>

      <v-divider class="my-3" color="greyBtn"></v-divider>

      <div class="d-flex align-center justify-space-between">
        <span class="font-18 font-600 uppercase">type</span>
        <span class="text-iconColor font-12 ls-02">moment</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isTraderSubscribe: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickButtonHandler() {
      if (this.isTraderSubscribe) {
        this.$emit("subscribeClicked");
      } else {
        this.$router.push("/subscribe");
      }
    },
  },
};
</script>

<style lang="scss">
.lock-subscribe {
  max-width: 400px;

  &__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
