import router from "@/router";
import { Purchases } from "@revenuecat/purchases-js";
import axios from "axios";
import { createStore } from "vuex";

const store = createStore({
  state: {
    text: "",
    color: "",
    timeout: "",
    loading: false,
    token: "",
    userDetails: {
    },
    loginChanged: false,
    noUserRoutes: [
      'Login',
      'Signup',
      'Forget-Password',
    ],
    selectedTrade: {},
    selectedPortfolio: {},
    isUserSubscribed: false
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    token(state) {
      return state.token;
    },
    userDetails(state) {
      return state.userDetails;
    },
    loginChanged(state) {
      return state.loginChanged;
    },
    noUserRoutes(state) {
      return state.noUserRoutes;
    },
    selectedTrade(state) {
      return state.selectedTrade;
    },
    selectedPortfolio(state) {
      return state.selectedPortfolio;
    },
    isUserSubscribed(state) {
      return state.isUserSubscribed;
    },
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.timeout = payload.timeout;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_TOKEN(state, payload) {
      if (payload?.token) {
        axios.defaults.headers.common = {
          "x-access-token": `${payload.token}`,
        };
        localStorage.setItem("token", payload.token);
        state.token = payload.token;
      }
    },
    REMOVE_USER(state) {
      delete axios.defaults.headers.common["x-access-token"]
      localStorage.removeItem("token");
      state.token = "";
      router.push('/auth/login')
      setTimeout(() => {
        router.go()
      }, 200);
    },
    SET_USER_DETAILS(state, payload) {
      state.userDetails = payload;
    },
    SET_LOGIN_CHANED(state) {
      state.loginChanged = !state.loginChanged
    },
    SET_TRADE_DETAILS(state, payload) {
      state.selectedTrade = payload
    },
    SET_SELECTED_PORTFOLIO(state, payload) {
      state.selectedPortfolio = payload
    },
    SET_SUBSCRIBTION_STATUS(state, payload) {
      state.isUserSubscribed = payload
    },
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    loading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    logout({ commit }) {
      commit("REMOVE_USER");
      commit("SET_LOGIN_CHANED");
    },
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
      commit("SET_LOGIN_CHANED");
    },
    setUserDetails({ commit }, payload) {
      commit("SET_USER_DETAILS", payload);
    },
    setTradeDetails({ commit }, payload) {
      commit("SET_TRADE_DETAILS", payload);
    },
    setSelectedPortfolio({ commit }, payload) {
      commit("SET_SELECTED_PORTFOLIO", payload);
    },
    async checkSubscription({ commit, state }) {
      await Purchases.configure(store.$revenuecatKey, state.userDetails?._id);
      try {
        const customerInfo =
          await Purchases.getSharedInstance().getCustomerInfo();
          
          console.log('customerInfo', customerInfo)
        if (customerInfo.entitlements.all["premium"]) {
          console.log("Entitlement 'premium' is active");
          commit('SET_SUBSCRIBTION_STATUS', true)
        } else {
          commit('SET_SUBSCRIBTION_STATUS', false)
          console.log("Entitlement 'premium' is not active");
        }
      } catch (e) {
        console.error("Error fetching customer info:", e);
      }
    },
  },
  modules: {},
});


export default store