// import store from "@/store";
import axios from "axios";
axios.defaults.baseURL = "https://backend.mrbitcoin.io/graphql";
axios.defaults.headers.common = {
  "x-access-token": `${localStorage.getItem("token")}`,
};


let axiosApp = () => {
  // Show loader in each request
  axios.interceptors.request.use(
    (config) => {
      // store.dispatch('loading', true);
      return config;
    },
    (error) => {
      return error;
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      console.log('error', error)
      // store.dispatch("showSnack", {
      //   text: error.response.data?.message || error.response.data?.message[0].message,
      //   color: "error",
      // });
      // Response with status code > 200 is here
      return error.response.data;
    }
  );

};

export default axiosApp