<template>
  <div>
    <base-input
      :noBorder="true"
      prependIcon="mdi-magnify"
      :defaultSelected="search"
      @changeSelected="search = $event"
      placeholder="Search currencies ..."
      :rules="[]"
    ></base-input>

    <div class="bg-darkbg">
      <div class="text-center py-3" v-if="loading">
        <span class="font-500 font-14 text-iconColor d-block">Loading...</span>
      </div>      
      <div v-if="!loading && items.length">
        <div
        class="border-bottom d-flex align-center px-5 py-4 cursor-pointer hover-light"
        :class="item.selected ? 'primary-transparent' : ''"
        v-for="(item, i) in items"
        @click="selectHandler(item)"
        :key="i"
      >
        <!-- <img class="radius-50" width="26" height="26" :src="item.url" /> -->
        <div class="mx-3">
          <span class="font-600 font-14 text-grey4 d-block">{{
            item.symbol
          }}</span>
          <!-- <span class="font-500 font-14 text-iconColor d-block">{{
            item.price
          }}</span> -->
        </div>
      </div>        
      </div>
      <div class="text-center py-3" v-if="!loading && items.length == 0">
        <span class="font-500 font-14 text-iconColor d-block">No Coins...</span>
      </div>
 
    </div>
    <step-buttons
      btnTitle="Next:Exchange"
      cancelTitle="Cancel"
      @clickHandler="nextHandler"
      @cancel="$emit('cancel')"
    ></step-buttons>
  </div>
</template>

<script>
import StepButtons from "./ButtonsSection.vue";
import { useStore } from "vuex";

export default {
  components: { StepButtons },
  props: {
    coins: {
      type: Array,
      default: () => [],
    },
    selectedCoinObj: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore();

    return { store};
  },
  data: () => ({
    selectedCoin: "",
    search: "",
  }),
  computed: {
    items() {
      let arr = this.coins.filter(
        (item) =>
          item.symbol.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
      return arr;
    },
  },
  methods: {
    nextHandler() {
      if (!this.selectedCoin) {
        this.store.dispatch("showSnack", {
          text: "Please select a coin to continue",
          color: "error",
        });
        return;
      }
      this.$emit("nextHandler", this.selectedCoin);
    },
    selectHandler(item) {
      this.coins.forEach((i) => {
        if (i.symbol == item.symbol) {
          i.selected = true;
          this.selectedCoin = item;
        } else {
          i.selected = false;
        }
      });
    },
  },
  created(){
    this.items = [...this.coins]
    this.selectedCoin = this.selectedCoinObj
  }
};
</script>

<style></style>
