import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, concat } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import store from '../store/index';

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
            store.dispatch('showSnack', {
                text: message,
                color: "error",
            });
            if (message == 'invalid token' || message == 'invalid signature') {
                store.dispatch("setUserDetails", {});
                store.dispatch("logout");
            }
        });
    }
    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
    }
});

const authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('token');

    operation.setContext({
        headers: {
            'x-access-token': token ? `${token}` : '',
        },
    });

    return forward(operation);
});


const httpLink = new HttpLink({
    uri: 'https://backend.mrbitcoin.io/graphql', // Replace with your GraphQL endpoint
});

const link = ApolloLink.from([errorLink, httpLink]);


const client = new ApolloClient({
    link: concat(authLink, link),
    cache: new InMemoryCache(),
});

export default client;