<template>
  <div class="input-style relative">
    <span
      class="font-16 d-block mb-1 lightwhite--text"
      :class="[textColor ? ' text-greyBtnText' : '', titleClasses]"
      >{{ title }}</span
    >
    <v-textarea
      class="w-100"
      :type="type"
      variant="outlined"
      :placeholder="placeholder"
      dense
      :rows="rows"
      :rules="rules"
      :hide-details="hideDetails"
      :rounded="rounded"
      full-width
      v-model="selected"
      :readonly="readonly"
      :no-resize="noResize"
      color="black"
      :bg-color="bgColor ? 'darkbg' : '#414152'"
      @click:append="$emit('appendClicked')"
    >
    </v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    defaultSelected: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasValue: {
      type: Boolean,
      default: false,
    },
    customIcon: {
      type: Boolean,
      default: false,
    },
    noResize: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: "",
    },
    titleClasses: {
      type: String,
      default: "",
    },
    rows: {
      type: String,
      default: "3",
    },
    bgColor: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    selected: {
      get() {
        return this.defaultSelected;
      },
      set(newVal) {
        if (this.selected !== newVal) {
          this.$emit("changeSelected", newVal);
        }
      },
    },    
  }  
};
</script>

<style lang="scss">
.input-style {
  .v-field__outline {
    --v-field-border-opacity: 0;
  }
  .v-field {
    border: 1px solid #1f1f2c !important;
    border-radius: 10px;
  }
  .v-input__slot {
    fieldset {
      height: 49px !important;
    }
    .v-input__append-inner {
      margin-top: 5px;
    }
    .v-text-field__slot {
      height: 44px !important;
    }
  }
  .error--text {
    .v-field__outline {
      --v-field-border-opacity: 0;
    }
    .v-field {
      border: 1px solid #1f1f2c !important;
      border-radius: 10px;
    }
  }
}
.base-phone input::-webkit-outer-spin-button,
.base-phone input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
</style>
