<template>
    <div ref="jazzicon" />
  </template>
  
  <script>
  import jazzicon from 'jazzicon';
  
  export default {
    props: {
      diameter: {
        type: Number,
        default: 50,
      },
      seed: {
        type: Number,
        required: true,
      },
    },
    mounted() {
      const icon = jazzicon(this.diameter, this.seed);
      this.$refs.jazzicon.appendChild(icon);
    },
  };
  </script>
  
  <style scoped>
  div {
    display: inline-block;
  }
  </style>