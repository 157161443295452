import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import client from "@/plugins/apolloClient";

export const AddTrade = async (obj) => {
  let variables = {
    input: { ...obj },
  };

  const { mutate: ADD_TRADE } = await useMutation(
    gql`
      mutation addTrade($input: addTradeInput!) {
        addTrade(input: $input) {
          trade {
            _id
            text
            createdAt
          }
          success
          message
        }
      }
    `,
    () => ({
      variables,
    })
  );

  try {
    let res = await ADD_TRADE();
    if (res.data?.addTrade) {
      return res.data.addTrade;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const FetchCoinsPairs = async () => {
  const query = gql`
    query getCoinsPairs {
      getCoinsPairs {
        symbol
        price
      }
    }
  `;

  try {
    let { result, loading } = await useQuery(query);
    if (loading) {
      return result;
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const FetchTradesByUserID = async (
  userId,
  status,
  orderType = "",
  pageNumber = 1
) => {
  let variables = {
    input: { pageNumber, pageSize: 20, userId, status, orderType },
  };

  console.log('variables', variables)

  try {
    const response = await client.query({
      query: gql`
        query getTradesByUser($input: paginationInput!) {
          getTrades(input: $input) {
            count
            trades {
              _id
              text
              withSubscription
              coins {
                symbol
                price
              }
              orderType
              tradeType
              tradeStatus
              risk
              reward
              stopLoss
              leverage
              timeTag
              takeProfit {
                amount
                percent
                price
                isClosed
              }
              tradeValidPercentageValue
              createdAt
              roi {
                coin
                roi
                isValid
              }
              user {
                username
                firstName
                image {
                  url
                  fileName
                }
              }
            }
          }
        }
      `,
      variables: variables,
      fetchPolicy: "no-cache",
    });

    console.log('response', response)
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const FollowTrade = async (id) => {
  let variables = {
    input: { id },
  };

  const { mutate: FOLLOW_TRADE } = await useMutation(
    gql`
      mutation toggleFollowTrade($input: getByIdInput!) {
        toggleFollowTrade(input: $input) {
          trade {
            _id
            user {
              _id
              fullName
            }
            reward
            risk
            coins {
              symbol
              price
            }
            roi {
              coin
              roi
            }
            takeProfit {
              amount
              percent
            }
            timeTag
            tradeStatus
            tradeType
            createdAt
            closedDate
            likes
            dislikes
            myReaction
            isPostFollowed
          }
          success
          message
        }
      }
    `,
    () => ({
      variables,
    })
  );

  try {
    let res = await FOLLOW_TRADE();
    if (res.data?.toggleFollowTrade) {
      return res.data.toggleFollowTrade;
    }
  } catch (err) {
    console.log("err", err);
  }
};

