<template>
  <div>
    <span class="font-16" :class="[checkFieldTextColor(value),classes]">
      <v-icon
        class="relative font-16"
        style="left: 3px"
        :color="checkFieldIconColor(value)"
        >{{ checkFieldIcon(value) }}</v-icon
      >
      {{ checkFieldFixedNumber(value) }}{{ hasPercentage ? "%" : "" }}</span
    >
  </div>
</template>

<script>
export default {
  inject: [
    "checkFieldTextColor",
    "checkFieldFixedNumber",
    "checkFieldIcon",
    "checkFieldIconColor",
  ],
  props: {
    value: {
      type: String,
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
    hasPercentage: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style></style>
