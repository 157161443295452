<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="data"
      :mobile-breakpoint="0"
      class="bg-cardbg radius-10 mt-6"
    >
      <template
        v-slot:[`item.${header.key}`]
        v-for="(header, i) in headers"
        :key="i"
      >
        <div class="d-flex align-center">
          <v-skeleton-loader
            v-if="header.key == 'name'"
            class="img-loader mr-2"
          ></v-skeleton-loader>
          <v-skeleton-loader class="text-loader"></v-skeleton-loader>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    data: [{}, {}, {}, {}, {}],
  }),
};
</script>

<style lang="scss">
.img-loader {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.text-loader {
  width: 80px;
  height: 18px;
  border-radius: 3px;
}
</style>
