<template>
  <div class="drawer px-5">
    <v-app-bar
      prominent
      class="w-100 d-flex align-center justify-space-between drawer-navbar drawer-navbar--scrolled"
      elevation="0"
    >
      <div class="d-flex align-center">
        <img
          @click="$router.push('/homepage')"
          class="cursor-pointer"
          :src="require('@/assets/images/global/logo.png')"
        />
      </div>
      <transition name="fade" mode="out-in">
        <v-app-bar-nav-icon
          class="text-primary"
          style="z-index: 999999"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </transition>
    </v-app-bar>

    <!-- drawer -->
    <v-navigation-drawer v-model="drawer" temporary left fixed>
      <!-- close -->
      <div class="text-right pa-2">
        <v-icon @click="drawer = false"> mdi-close-circle </v-icon>
      </div>

      <div class="px-5">
        <!-- logo -->
        <router-link to="/">
          <img :src="require('@/assets/images/global/logo-footer.png')" />
        </router-link>

        <!-- get started -->
        <v-btn
          color="primary"
          elevation="0"
          class="radius-10 mt-5 mb-4 d-block w-100"
          height="50"
          @click="$router.push('/auth/login')"
          v-if="!userDetails?.username"
        >
          <span class="font-16 d-inline-block mb-1">Get Started</span>
        </v-btn>

        <!-- get started -->
        <v-btn
          color="primary"
          elevation="0"
          class="radius-10 mt-5 mb-4 d-block w-100"
          height="50"
          @click="$router.push('/become-trader')"
          v-if="userDetails?.role == 'user'"
        >
          <span class="font-16 d-inline-block mb-1">Become Trader</span>
        </v-btn>
      </div>
      <v-list class="px-3" v-model="group">
        <router-link
          to="/top-traders"
          class="cursor-pointer px-3 py-2 navbar-link d-block"
          :class="checkActivePage('Top-Traders')"
        >
          <span class="font-16 font-500">Top Traders</span>
        </router-link>
        <router-link
          to="/corner"
          class="cursor-pointer px-3 py-2 navbar-link d-block"
          :class="checkActivePage('Corner')"
        >
          <span class="font-16 font-500">MR.B Corner</span>
        </router-link>
        <router-link
          to="/portfolio"
          class="cursor-pointer px-3 py-2 navbar-link d-block"
          :class="checkActivePage('Portfolio')"
        >
          <span class="font-16 font-500">Portfolio</span>
        </router-link>
        <router-link
          to="/data-page"
          class="cursor-pointer px-3 py-2 navbar-link d-block"
          :class="checkActivePage('Data-Page')"
        >
          <span class="font-16 font-500">Data</span>
        </router-link>
        <router-link
          to="/news"
          class="cursor-pointer px-3 py-2 navbar-link d-block"
          :class="checkActivePage('News')"
        >
          <span class="font-16 font-500">News</span>
        </router-link>
      </v-list>
      <user-menu class="px-1" v-if="userDetails?.username"></user-menu>

    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserMenu from "./components/UserMenu.vue";

export default {
  components: { UserMenu },
  data: () => ({
    drawer: false,
    group: "",
  }),
  computed: {
    ...mapGetters(["userDetails"]),
  },
  watch: {
    $route() {
      this.checkActivePage();
    },
  },
  methods:{
    checkActivePage(page) {
      if (this.$route.name == page) return "active-link";
      else return "";
    },    
  }
};
</script>

<style lang="scss">
.drawer {
  height: 80px;
  .v-toolbar__content {
    width: 100%;
    background: #191820;
    padding: 10px 10px 10px 20px;
    height: 80px !important;
    justify-content: space-between;
  }
  .v-app-bar-nav-icon {
    margin: 0px !important;
  }

  .v-navigation-drawer {
    position: fixed !important;
    width: 260px !important;
    height: 100vh !important;
    z-index: 9999999 !important;
    top: 0px !important;
    background: #2e2d35;
  }
}
.active-link {
  background: rgba(216, 216, 216, 0.246);
  border-radius: 30px;
}
.navbar-link{
  transition: 0.3s;
}
</style>
