import { gql } from "@apollo/client/core";
import client from "@/plugins/apolloClient";

export const SendMessageToChat = async (message) => {
  let variables = {
    input: { message },
  };

  try {
    const response = await client.query({
      query: gql`
        query askChatGpt($input: msgInput!) {
          askChatGpt(input: $input) {
            success
            data
          }
        }
      `,
      variables: variables,
      fetchPolicy: "no-cache",
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
