<template>
    <div>
      <v-btn
        elevation="0"
        :color="color"
        class="radius-8"
        height="44px"
        :disabled="loading"
        min-width="150px"
        @click="$emit('clickHandler')"
      >
        <span class="mx-1 font-16 font-500 text-white px-6 ls-04" v-if="!loading">{{
          title
        }}</span>
        <span v-if="loading" class="px-12 d-inline-block"><global-spinner></global-spinner></span>
      </v-btn>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: "",
      },
      color: {
        type: String,
        default: "primary",
      },
    },
  };
  </script>
  
  <style></style>
  