
// Vuetify
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";


// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";


const myCustomLightTheme = {
  dark: true,
  colors: {
    primary: "#495BFF",
    primaryOpacity: "#23243b",
    bluelight: '#5F6D98', 
    darkbg: "#191820",
    white: "#fff",
    white2: "#f7f7ff",
    white3: "#EDEEFF",
    grey: "#B9B8BB",
    greyBtn: "#2F2F3B",
    greyBtnText: "#B7B7E0",
    blue: "#3975EA",
    green1: "#00c68b",
    red1: "#EB5757",
    darkgreen: '#0c3529',
    darkred: "#661919",
    grey3: "#8585A3",
    iconColor: "#686880",
    orange1: "#E3642E",
    cardbg: "#1f1f2c",
    darkbg2: "#15141a",
    grey4: "#CFCFFF",
    grey5: "#9C9CBF",
    yellow1: "#FFC451",
    yellow: "#F0B90B",

  },
}


export default createVuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 960,
      lg: 1280,
    },
    scrollBarWidth: 24,
  },
  components,
  directives,
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme
    },
  },
});
