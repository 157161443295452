<template>
  <div
    class="input-style relative"
    :class="noBorder ? 'input-style--noborder' : ''"
  >
    <span
      class=" d-block mb-1 font-14 lightwhite--text"
      :class="[textColor ? ' text-greyBtnText' : 'text-grey3 ']"
      >{{ title }}</span
    >
    <v-text-field
      class="w-100"
      :type="type"
      variant="outlined"
      :placeholder="placeholder"
      dense
      height="44px"
      max-height="44px"
      :rules="rules"
      :prepend-inner-icon="prependIcon"
      :hide-details="hideDetails"
      v-model="selected"
      :rounded="rounded"
      full-width
      :disabled="disabled"
      :readonly="readonly"
      :append-icon="isPassword ? type == 'password' ? 'mdi-eye': 'mdi-eye-off': ''"
      @click:append="$emit('appendClicked')"
      color="black"
      step=".1"
      :bg-color="bgColor ? 'darkbg' : ''"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    defaultSelected: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    title: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasValue: {
      type: Boolean,
      default: false,
    },
    customIcon: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    bgColor: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selected: {
      get() {
        return this.defaultSelected;
      },
      set(newVal) {
        if (this.selected !== newVal) {
          this.$emit("changeSelected", newVal);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.input-style {
  .v-field__outline {
    --v-field-border-opacity: 0;
  }
  .v-field {
    border: 1px solid #1f1f2c !important;
    border-radius: 10px;

    &:hover {
      .v-field__outline {
        --v-field-border-opacity: 0 !important;
      }
    }
  }
  .v-input__slot {
    fieldset {
      height: 49px !important;
    }
    .v-input__append-inner {
      margin-top: 5px;
    }
    .v-text-field__slot {
      height: 44px !important;
    }
  }
  .error--text {
    .v-field__outline {
      --v-field-border-opacity: 0;
    }
    .v-field {
      border: 1px solid #1f1f2c !important;
      border-radius: 10px;
    }
  }
  .v-field__prepend-inner {
    margin-right: 6px;
  }

  &--noborder {
    .v-field {
      border: none !important;
    }
  }
  input {
    min-height: 44px !important;
    padding: 0px 8px
  }
}
.base-phone input::-webkit-outer-spin-button,
.base-phone input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
.base-phone input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
