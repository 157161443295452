<template>
  <div>
    <v-form @submit.prevent="nextHandler" ref="goalForm">
      <v-row class="ma-0 bg-darkbg px-2 pb-5">
        <!-- Leverage -->
        <v-col cols="6" class="pb-1">
          <base-input
            placeholder="00.00"
            title="Leverage"
            type="number"
            v-if="objStep.tradeType != 'spot'"
            :defaultSelected="obj.leverage"
            @changeSelected="obj.leverage = $event"
            :rules="[]"
          ></base-input>
        </v-col>

        <!-- Risk -->
        <v-col cols="6" class="pb-1">
          <base-input
            placeholder="00.00%"
            title="Risk"
            type="number"
            :defaultSelected="obj.risk"
            @changeSelected="obj.risk = $event"
            :rules="[]"
          ></base-input>
        </v-col>

        <!-- Reward -->
        <v-col cols="6" class="pb-1">
          <base-input
            placeholder="00.00"
            title="Risk Reward (RR)"
            type="number"
            :defaultSelected="obj.reward"
            @changeSelected="obj.reward = $event"
            :rules="[]"
          ></base-input>
        </v-col>

        <!-- Stop Loss -->
        <v-col cols="12" class="pb-1">
          <base-input
            placeholder="2%"
            title="Trade Valid"
            type="number"
            :defaultSelected="obj.tradeValidPercentageValue"
            @changeSelected="obj.tradeValidPercentageValue = $event"
          ></base-input>
          <p class="font-16 text-white mb-2 mt-1">
            Trade is valid for followers to take until the price
            {{ obj.tradeValidPercentageValue || "..." }}% away from entry
          </p>
        </v-col>

        <!-- Stop Loss -->
        <v-col cols="12" class="pb-1">
          <span class="text-primary font-18 font-600 d-block mb-1"
            >Stop Loss</span
          >
          <base-input
            placeholder="manual"
            title="Price"
            type="number"
            :defaultSelected="obj.stopLoss"
            @changeSelected="obj.stopLoss = $event"
            :rules="[]"
          ></base-input>
        </v-col>

        <!-- Take Profit -->
        <v-col cols="12" class="pb-1">
          <span class="font-16 text-primary font-500">Take Profit</span>
        </v-col>
        <v-row
          class="ma-0 py-4 px-2 bg-primaryOpacity radius-8 relative mb-3"
          v-for="(profit, i) in profitList"
          :key="i"
        >
          <v-icon
            class="cursor-pointer close-profit"
            @click="removeProfitHandler(i)"
            v-if="i != 0"
            >mdi-close</v-icon
          >
          <v-col cols="6" class="pb-1 pt-1">
            <base-input
              placeholder="manual"
              title="Price"
              type="number"
              :defaultSelected="profit.price"
              @changeSelected="
                [changeProfitPriceHandler($event, i), (profit.price = $event)]
              "
              :rules="[]"
            ></base-input>
          </v-col>
          <v-col cols="6" class="pb-1 pt-1">
            <base-input
              placeholder="manual"
              title="Percentage"
              type="number"
              :defaultSelected="profit.percent"
              @changeSelected="
                [
                  changeProfitPercentHandler($event, i),
                  (profit.percent = $event),
                ]
              "
              :rules="[]"
            ></base-input>
          </v-col>
          <v-col cols="12" class="pb-1 mt-3 pt-1">
            <div class="px-2 py-3 radius-8 bg-cardbg">
              <span class="text-white">{{ profit.amount }}</span>
            </div>
            <div class="mt-2 px-5">
              <v-slider
                track-color="white"
                color="#646ec3"
                show-ticks
                class="mx-auto d-block"
                thumb-color="primary"
                v-model="profit.amount"
                :max="100"
                :min="0"
                :step="25"
                hide-details
              ></v-slider>
            </div>
          </v-col>
        </v-row>
        <span
          v-if="profitList.length < 4"
          class="font-14 text-grey5 cursor-pointer px-3"
          @click="addProfitHandler"
          >+ Add take profit</span
        >
      </v-row>
    </v-form>

    <step-buttons
      btnTitle="Publish"
      cancelTitle="Back"
      @clickHandler="nextHandler"
      @cancel="$emit('back')"
      :loading="loading"
    ></step-buttons>
  </div>
</template>

<script>
import { useStore } from "vuex";
import StepButtons from "./ButtonsSection.vue";
export default {
  props: {
    objStep: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: { StepButtons },
  setup() {
    const store = useStore();

    return { store };
  },
  data: () => ({
    obj: {},
    goalForm: null,
    value: 100,
    profitList: [{ amount: 100, price: 0, percent: 0 }],
    entryPrice: 0,
  }),
  methods: {
    addProfitHandler() {
      if (this.profitList.length < 4) {
        this.profitList.push({ amount: 100, price: 0, percent: 0 });
      }
    },
    removeProfitHandler(index) {
      this.profitList.splice(index, 1);
    },
    changeProfitPriceHandler(price, i) {
      this.profitList[i].percent = (
        ((price - this.entryPrice) * 100) /
        this.entryPrice
      ).toFixed(2);
    },
    changeProfitPercentHandler(percent, i) {
      this.profitList[i].price = (
        (percent * this.entryPrice) / 100 +
        this.entryPrice
      ).toFixed(2);
    },
    async nextHandler() {
      let validObj = await this.$refs.goalForm.validate();
      let errMessage = "";

      if (!validObj.valid) {
        errMessage = "Please check all the fields to continue.";
      }

      // validate leverage
      if (!this.obj.leverage) this.obj.leverage = null;

      // validation stoploss

      if (this.obj.timeTag == "long") {
        if (+this.obj.stopLoss > +this.entryPrice)
          errMessage = "Stop loss should be less than the entry price";
      } else {
        if (+this.obj.stopLoss < +this.entryPrice)
          errMessage = "Stop loss should be higher than the entry price";
      }

      if (errMessage) {
        this.store.dispatch("showSnack", {
          text: errMessage,
          color: "error",
        });
        return;
      }

      this.obj.takeProfit = this.profitList;
      this.$emit("submitTradeHandler", this.obj);
    },
  },
  created() {
    this.obj = this.objStep;
    this.entryPrice = +this.objStep.entries[0].value;
    if (this.objStep.tradeType == "spot") {
      this.obj.leverage = "1";
    }
  },
};
</script>

<style lang="scss">
.close-profit {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
