export default {
    beforeMount(el, binding) {
      el.clickOutsideEvent = (event) => {
        // Check if the click was outside the element and its children
        if (!(el === event.target || el.contains(event.target))) {
          // If so, call the method provided in the binding
          binding.value(event);
        }
      };
      document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
      document.removeEventListener('click', el.clickOutsideEvent);
    },
  };