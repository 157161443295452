<template>
  <div>
    <v-menu
      offset-y
      left
      max-height="400"
      :min-width="200"
      :width="200"
      content-class=" custom-menu radius-15"
    >
      <template v-slot:activator="{ props }">
        <div
          v-bind="props"
          class="d-flex algin-center justify-space-between bg-lightbg radius-5 px-3 py-1 ml-2 cursor-pointer"
        >
          <div class="d-flex align-center">
            <div v-if="userDetails?.username">
              <span class="d-block text-lightblackText font-16 font-600">
                <span
                  class="text-lightblackText font-16 font-600"
                  v-if="userDetails.role == 'admin'"
                  >Admin:
                </span>
                {{ userDetails?.username }}</span
              >
            </div>
            <img
              width="24"
              height="18"
              class="ml-2"
              :src="require('@/assets/images/icons/arrow-down-white.svg')"
            />
          </div>
          <v-tooltip text="View Profile" location="bottom">
            <template v-slot:activator="{ props }">
              <jazz-icon
                v-bind="props"
                :diameter="40"
                :seed="userDetails?._id"
                class="mx-2"
              ></jazz-icon>
            </template>
          </v-tooltip>
        </div>
      </template>
      <div class="bg-cardbg">
        <div class="px-2 pt-3" v-if="userDetails.role == 'trader'">
          <v-btn
            class="radius-5"
            color="lightprimary"
            elevation="0"
            width="100%"
            block
            @click="$router.push('/my-profile')"
            ><span class="text-white font-13">Profile</span></v-btn
          >
        </div>
        <div class="px-2 mt-2 pb-3">
          <v-btn
            class="radius-5 bg-primary"
            elevation="0"
            width="100%"
            block
            @click="logoutDialog = true"
            ><span class="text-white font-13">Logout</span></v-btn
          >
        </div>
      </div>
    </v-menu>
    <!-- logout -->
    <v-dialog
      v-model="logoutDialog"
      max-width="500px"
      content-class="bg-primaryOpacity pa-4 radius-15"
    >
      <logout-dialog @close="logoutDialog = false"></logout-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LogoutDialog from "../../components/dialogs/LogoutDialog.vue";
export default {
  components: { LogoutDialog },
  data: () => ({
    logoutDialog: false,
  }),
  methods: {},
  computed: {
    ...mapGetters(["userDetails"]),
  },
};
</script>

<style></style>
