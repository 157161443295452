<template>
    <div
      class="post-btns d-flex align-center justify-space-between py-4 mt-2 px-5"
    >
      <v-btn
        elevation="0"
        color="greyBtn"
        class="radius-8"
        height="44px"
        @click="$emit('cancel')"
      >
        <span class="mx-1 font-16 font-500">{{ cancelTitle }}</span>
      </v-btn>
  
      <base-button
        :loading="loading"
        :title="btnTitle"
        @clickHandler="$emit('clickHandler')"
      ></base-button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      btnTitle: {
        type: String,
        default: "",
      },
      cancelTitle: {
        type: String,
        default: "",
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
  };
  </script>
  
  <style lang="scss">
  .post-btns {
    position: sticky;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: #171923;
    z-index: 9;
    transition: 0.05s;
  }
  </style>
  