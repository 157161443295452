<template>
  <div class="input-style base-select relative">
    <span
      class="font-16 d-block mb-1 lightwhite--text"
      :class="[textColor ? ' text-greyBtnText' : '']"
      >{{ title }}</span
    >
    <v-select
      class="w-100"
      :items="items"
      variant="outlined"
      :placeholder="placeholder"
      dense
      height="44px"
      max-height="44px"
      :rules="rules"
      :hide-details="hideDetails"
      v-model="selected"
      :rounded="rounded"
      full-width
      :item-title="itemText"
      :item-value="itemValue"
      :readonly="readonly"
      color="black"
      :bg-color="bgColor ? 'darkbg' : ''"
      @click:append="$emit('appendClicked')"
      theme="dark"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    defaultSelected: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || "this field is require"],
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasValue: {
      type: Boolean,
      default: false,
    },
    customIcon: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: "title",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    bgColor: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selected: {
      get() {
        return this.defaultSelected || this.placeholder;
      },
      set(newVal) {
        if (this.selected !== newVal) {
          this.$emit("changeSelected", newVal);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.base-select {
  .v-field {
    padding: 0px 10px;
  }
}
</style>
