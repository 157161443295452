<template>
  <div
    class="navbar relative d-flex align-center w-100"
    :key="'change navbar ' + token"
  >
    <drawer-app v-if="$vuetify.display.smAndDown"></drawer-app>
    <div
      class="max-width mt-4 mb-2 hidden-sm-and-down z-9 w-100"
      v-if="$vuetify.display.mdAndUp"
    >
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center h-100">
          <!-- logo -->
          <img
            @click="$router.push('/homepage')"
            class="cursor-pointer"
            :src="require('@/assets/images/global/logo.png')"
          />
          <!-- links -->
          <transition name="fade" mode="out-in">
            <div
              class="navbar__links py-3 d-flex align-center justify-center w-100"
              :class="$vuetify.rtl ? 'pr-7' : 'pl-7'"
            >
              <router-link
                to="/"
                class="cursor-pointer mx-2 px-3 py-2 navbar-link"
                :class="checkActivePage('Homepage')"
              >
                <span class="font-16 font-500">Homepage</span>
              </router-link>
              <router-link
                to="/top-traders"
                class="cursor-pointer mx-2 px-3 py-2 navbar-link"
                :class="checkActivePage('Top-Traders')"
              >
                <span class="font-16 font-500">Top Traders</span>
              </router-link>
              <router-link
                to="/corner"
                class="cursor-pointer mx-2 px-3 py-2 navbar-link"
                :class="checkActivePage('Corner')"
              >
                <span class="font-16 font-500">MR.B Corner</span>
              </router-link>
              <router-link
                to="/portfolio"
                class="cursor-pointer mx-2 px-3 py-2 navbar-link"
                :class="checkActivePage('Portfolio')"
              >
                <span class="font-16 font-500">Portfolio</span>
              </router-link>
              <router-link
                to="/data-page"
                class="cursor-pointer mx-2 px-3 py-2 navbar-link"
                :class="checkActivePage('Data-Page')"
              >
                <span class="font-16 font-500">Data</span>
              </router-link>
              <router-link
                to="/news"
                class="cursor-pointer mx-2 px-3 py-2 navbar-link"
                :class="checkActivePage('News')"
              >
                <span class="font-16 font-500">News</span>
              </router-link>
            </div>
          </transition>
        </div>
        <!-- buttons logged -->
        <div class="navbar__buttons d-flex align-center">
          <!-- create a post -->
          <v-tooltip text="Create a post" location="bottom">
            <template v-slot:activator="{ props }">
              <img
                v-bind="props"
                class="mx-3 cursor-pointer"
                :src="require('@/assets/images/icons/edit.svg')"
                @click="postDialogHandler"
              />
            </template>
          </v-tooltip>

          <!-- notification -->
          <v-tooltip text="Notifications" location="bottom">
            <template v-slot:activator="{ props }">
              <v-icon
                @click="$router.push('/notifications')"
                class="mx-3 cursor-pointer"
                color="grey5"
                v-bind="props"
                >mdi-bell</v-icon
              >
            </template>
          </v-tooltip>

          <!-- signin -->
          <user-menu v-if="userDetails?.username"></user-menu>
          <v-btn
            color="primary"
            elevation="0"
            class="radius-10"
            height="50"
            @click="$router.push('/auth/login')"
            v-if="!userDetails?.username"
          >
            <span class="font-16 d-inline-block mx-5">Get Started</span>
          </v-btn>
          <!-- become trader -->
          <v-tooltip
            v-if="userDetails?.role == 'user'"
            text="Become Trader"
            location="bottom"
          >
            <template v-slot:activator="{ props }">
              <img
                v-bind="props"
                class="cursor-pointer"
                :src="require('@/assets/images/icons/user-lock.svg')"
                @click="$router.push('/become-trader')"
              />
              <!-- v-if="token && user.role != 'trader'" -->
            </template>
          </v-tooltip>
        </div>
      </div>
    </div>

    <v-dialog v-model="postDialog" max-width="600" content-class="radius-10">
      <create-post @close="postDialog = false"></create-post>
    </v-dialog>
  </div>
</template>

<script>
import DrawerApp from "./DrawerApp.vue";
import { FetchUserProfile } from "@/services/User";
import { mapGetters, useStore } from "vuex";
import UserMenu from "./components/UserMenu.vue";
import { watchEffect } from "vue";
import CreatePost from "@/components/dialogs/create-post/CreateView.vue";
import { loadStripe } from "@stripe/stripe-js";

export default {
  components: {
    DrawerApp,
    UserMenu,
    CreatePost,
  },
  setup() {
    const store = useStore();

    const fetchUserHandler = async () => {
      if (localStorage.getItem("token")) {
        let res = await FetchUserProfile();
        watchEffect(() => {
          if (res.value) {
            store.dispatch("setUserDetails", res.value.getProfile?.user);
          }
        });
      }
    };

    return { store, fetchUserHandler };
  },

  data: () => ({
    postDialog: false,
  }),
  computed: {
    ...mapGetters(["userDetails", "isUserSubscribed", "noUserRoutes", "token"]),
  },
  watch: {
    $route() {
      this.checkActivePage();
    },
  },
  methods: {
    postDialogHandler() {
      if (this.userDetails?.username && this.userDetails.role == 'trader') {
        this.postDialog = true;
      } else {
        if(this.userDetails._id){
          this.$router.push("/become-trader");
        }else{
          this.$router.push("/auth/login");
        }
      }
    },
    async loadStripeHandler() {
      await loadStripe(this.$stripeKey);
    },
    checkActivePage(page) {
      if (this.$route.name == page) return "active-link";
      else return "";
    },
  },
  created() {
    if (!this.noUserRoutes.includes(this.$route.name) && this.token) {
      this.fetchUserHandler();
    }

    this.$watch(
      () => this.userDetails._id,
      async () => {
        if (this.userDetails?._id && this.$route.name != "Subscribe") {
          this.loadStripeHandler();
        }
      },
      { immediate: true }
    );
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  z-index: 11;
  transition: 0.3s;
  box-shadow: 0px 4px 20px 0px #00000014;
  background: #191820;
  padding: 5px 0px;

  &__links {
    border-radius: 18px;
    span {
      color: white;
      transition: 0.3s;
    }
  }

  &__logo {
    height: 50px;
    transition: 0.3s;
  }
}
.v-menu__content {
  z-index: 999999999 !important;
}
.active-link {
  background: rgba(216, 216, 216, 0.246);
  border-radius: 30px;
}
.navbar-link {
  transition: 0.3s;
}
</style>
